export class IdHelper {

  get(length, letters = true, numbers = true) {
    let result = '';
    let characters = '';
    if (letters === true) {
      characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    }
    if (numbers === true) {
      characters += '0123456789';
    }
    const charactersLength = characters.length;
    if (letters && numbers) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength - 9));
    }
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
