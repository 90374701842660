import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable, NgZone, OnInit } from '@angular/core';
import { AuthenticationCoreService } from 'authentication-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserCoreService } from 'user-core';
import { environment } from '../../environments/environment';
import { CurrentUserService } from '../core/services/current-user.service';
import { UserGroups } from '../models/user-group.interface';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService{

  url = environment.api.authentication.usergroups;

  groups: UserGroups;

  gotGroups: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationCoreService,
    private currentUserService: CurrentUserService,
    private zone: NgZone,
  ) {
  }

  public initialise(){
    this.setup();
    this.authenticationService.authenticationChange.subscribe(()=>{
        this.setup();
    });
  }

  private setup() {
    this.currentUserService.getUser().subscribe((user)=>{
      if (user) {
        this.groups = this.getGroups();
        if (!this.groups) {
          this.http.get<UserGroups>(this.url.get.uri, { withCredentials: this.url.get.protected }).subscribe((groups: UserGroups)=>{
            this.groups = groups;
            this.set(groups);
            this.gotGroups.next(true);
          });
        }
      }else{
        this.clear();
      }
    })
  }

  set(groups: UserGroups) {
    localStorage.setItem('user_groups', JSON.stringify(groups));
    return groups;
  }

  getGroups(): UserGroups {
    return JSON.parse(localStorage.getItem('user_groups'));
  }

  clear() {
    this.groups = { is_client: false, is_planner: false, is_venue_owner: false };
    localStorage.removeItem('user_groups');
    this.gotGroups.next(false);
  }

  hasPermission(permissions, operation_mode) {
    let hasPermission = false;

    if (!this.groups) {
      return false;
    }

    for (let permission of permissions) {
      let str = 'is_' + permission;

      if (this.groups[str]) {//mode == OR || mode == NOT
        if (operation_mode == 'OR') {
          return true;
        }
        hasPermission = true;
      } else if (operation_mode == 'AND') {
        return false;
      }
    }
    return hasPermission;
  }

}
