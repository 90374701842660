import {Component, ElementRef, OnInit} from '@angular/core';
import {IdHelper} from '../../../../helpers/id.helper';
import {Contact} from '../../../../models/contact.interface';
import {ContactService} from '../../../../services/contact.service';
import {SearchInputDropdownContainer} from '../search-input-dropdown.container';

@Component({
  selector: 'planner-search-input-dropdown',
  templateUrl: '../search-input-dropdown.container.html',
  styleUrls: ['../search-input-dropdown.container.scss']
})

export class PlannerSearchInputDropdownContainer extends SearchInputDropdownContainer implements OnInit {

  items: Contact[];

  constructor(
    private contactService: ContactService,
    private el: ElementRef,
    public idHelper: IdHelper,
  ) {
    super(idHelper);
    this.regiserElement(el);
  }

  search() {
    this.loading = true;
    const params = [{field: 'is_planner', value: true}];
    this.contactService.search(this.searchString || '', 1, null, null, params).subscribe(response => {
      this.items = response.results;
      this.itemsTotal = response.count;
      this.loading = false;
    });
  }

  getItemLabel(item): string {
    return `${item.name} ${item.surname}`;
  }
}


