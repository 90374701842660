import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationCoreService } from 'authentication-core';
import { environment } from '../environments/environment';
import { CurrentUserService } from './core/services/current-user.service';
import { ChatService } from './services/chat.service';
import { ModalService } from './services/modal.service';
import { ToastService } from './services/toast.service';
import { UserGroupService } from './services/user-group.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  environment = environment;
  version = 'Unknown';

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private router: Router,
    private authenticationCoreService: AuthenticationCoreService,
    private userGroupService: UserGroupService,
    private currentUserService: CurrentUserService,
    private chatService: ChatService
  ) {

    this.userGroupService.initialise();
    this.version = this.environment.version;

    if (!navigator.onLine) {
      this.toastService.add({
        title: 'Network Issue',
        message: 'No network connection detected. The application may not operate correctly until the connection is restored',
        autoClose: 7000
      });
    }

    window.addEventListener('online', () => {
      this.toastService.add({
        title: 'Network Restored',
        message: 'Network connection restored. Please refresh if you continue to have issues',
        autoClose: 7000
      });
    });

    window.addEventListener('offline', () => {
      this.toastService.add({
        title: 'Network Issue',
        message: 'No network connection detected. The application may not operate correctly until the connection is restored',
        autoClose: 7000
      });
    });
  }

  ngOnInit() {
  }

  isLoggedIn() {
    return this.authenticationCoreService.isAuthenticated();
  }

  @HostListener('document:keyup', ['$event'])
  keyUpEvent(event) {
    if (event.key === 'Escape') {
      this.modalService.closeAll();
    }
  }
}
