import {EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

export abstract class FormContainer implements OnInit, OnChanges {

  @Input() instance;

  @Output() addEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() editEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() cancelEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() deleteEvent: EventEmitter<null> = new EventEmitter<null>();

  form: FormGroup;
  errors = {} as any;

  constructor() {

  }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {

  }

  deleteButtonPress() {
    this.deleteItem();
  }

  cancelButtonPress() {
    this.cancelEvent.emit();
    this.cleanupForm();
  }

  handleFormSubmit() {
    if (this.instance) {
      this.editItem();
    } else {
      this.addItem();
    }
  }

  addItem(data?: any) {
    this.addEvent.emit(data);
    this.cleanupForm();
  }

  editItem() {
    this.editEvent.emit();
    this.cleanupForm();
  }

  deleteItem() {
    this.deleteEvent.emit();
    this.cleanupForm();
  }

  initForm() {
  }

  comparator(object1: any, object2: any) {
    return object1 && object2 ? object1.id === object2.id : object1 === object2;
  }

  cleanupForm() {
    this.form.reset();
    this.errors = {};
  }

  setInitialValue(value: string){
  }

}

