import {Component, ElementRef, OnInit} from '@angular/core';
import {IdHelper} from '../../../../helpers/id.helper';
import {Wedding} from '../../../../models/wedding.interface';
import {WeddingService} from '../../../../services/wedding.service';
import {SearchInputDropdownContainer} from '../search-input-dropdown.container';

@Component({
  selector: 'wedding-search-input-dropdown',
  templateUrl: '../search-input-dropdown.container.html',
  styleUrls: ['../search-input-dropdown.container.scss']
})

export class WeddingSearchInputDropdownContainer extends SearchInputDropdownContainer implements OnInit {

  items: Wedding[];
  hasDetailedInformation = true;

  constructor(
    private weddingService: WeddingService,
    private el: ElementRef,
    public idHelper: IdHelper,
  ) {
    super(idHelper);
    this.regiserElement(el);
  }

  search() {
    this.loading = true;
    this.weddingService.search(this.searchString || '').subscribe(response => {
      this.items = response.results;
      this.itemsTotal = response.count;
      this.loading = false;
    });
  }

  getItemDetail(item: Wedding): string {
    const dayGuests = item.day_guests_adult + item.day_guests_child;
    const eveningGuests = item.evening_guests_adult + item.evening_guests_child;
    return `${item.reference} | Planner: ${item.planner.name} | Venue: ${item.venue.name} | Date: ${item.date} | Guests: ${dayGuests} (Day), ${eveningGuests} (Evening)`;
  }

  getItemLabel(item: Wedding): string {
    return `${item.couple_a.name_combined} & ${item.couple_b.name_combined} - ${item.title}`;
  }
}


