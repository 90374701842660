import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ContactSearchStorage {

  private storageIdentifier = 'contactsSearch';

  set(query: string, page: number, sortField: string, sortAsc: boolean) {
    localStorage.setItem(this.storageIdentifier, JSON.stringify({query, page, sortField, sortAsc}));
  }

  get(): object {
    return JSON.parse(localStorage.getItem(this.storageIdentifier));
  }

  clear() {
    localStorage.removeItem(this.storageIdentifier);
  }
}
