import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrentUserService } from 'src/app/core/services/current-user.service';
import { User } from 'src/app/models/user.interface';
import { TableLayoutService } from 'src/app/services/table-layout.service';
import { Event } from '../../../../models/event.interface';
import { PersonalTaskService } from '../../../../services/personal-task.service';
import { ToastService } from '../../../../services/toast.service';
import { FormContainer } from '../form.container';

@Component({
  selector: 'personal-task-form',
  templateUrl: './personal-task-form.container.html'
})

export class PersonalTaskFormContainer extends FormContainer implements OnChanges {

  @Input() instance: Event;

  priority = 2;

  constructor(
    private toastService: ToastService,
    private personalTaskService: PersonalTaskService,
    private currentUserService: CurrentUserService,
  ) {
    super();
  }

  ngOnChanges() {
    if (this.instance) {
      this.priority = this.instance.priority;
      this.form.get('title').patchValue(this.instance.title);
      this.form.get('summary').patchValue(this.instance.summary);
      this.form.get('details').patchValue(this.instance.details);
      this.form.get('priority').patchValue(this.instance.priority);
      this.form.get('end_date').patchValue(this.instance.end_date);
      this.form.get('end_time').patchValue(this.instance.end_time);
      this.form.get('responsible').patchValue(this.instance.responsible);
      this.form.get('completed').patchValue(this.instance.completed);
    }
  }

  initForm() {
    this.form = new FormGroup({
      title: new FormControl(null),
      summary: new FormControl(null),
      details: new FormControl(null),
      priority: new FormControl(null),
      end_date: new FormControl(null, [Validators.required]),
      end_time: new FormControl(null),
      responsible: new FormControl(null),
      completed: new FormControl(null)
    });
  }

  addItem() {
    if (!this.form.valid) {
      return;
    }
    this.currentUserService.getUser().subscribe((user: User) => {
      this.personalTaskService.add({
        title: this.form.get('title').value,
        summary: this.form.get('summary').value,
        details: this.form.get('details').value,
        // TODO: Change to letter format once implemented in backend
        event_type: ['3'],
        owner: user.contact,
        end_date: this.form.get('end_date').value || null,
        end_time: this.form.get('end_time').value || null,
        priority: this.priority,
        completed: this.form.get('completed').value === true,
        responsible: null
      }).subscribe(() => {
        this.toastService.add({ title: 'Success', message: 'Task added successfully' });
        super.addItem();
      }, error => {
        this.errors = error.error;
      });
    });
  }

  editItem() {
    if (!this.form.valid) {
      return;
    }
    this.currentUserService.getUser().subscribe((user: User) => {
      this.personalTaskService.update(this.instance.id, {
        title: this.form.get('title').value,
        summary: this.form.get('summary').value,
        details: this.form.get('details').value,
        // TODO: Change to letter format once implemented in backend
        event_type: ['3'],
        owner: user.contact,
        end_date: this.form.get('end_date').value || null,
        end_time: this.form.get('end_time').value || null,
        priority: this.priority,
        completed: this.form.get('completed').value === true,
        responsible: null
      }).subscribe(() => {
        this.toastService.add({ title: 'Success', message: 'Task updated successfully' });
        super.editItem();
      }, error => {
        this.errors = error.error;
      });
    });
  }

  deleteItem() {
    if (window.confirm('Are you sure you want to delete this task?')) {
      this.personalTaskService.delete(this.instance.id).subscribe(() => {
        this.toastService.add({ title: 'Success', message: 'Table deleted successfully' });
        super.deleteItem();
      });
    }
  }

}
