import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {ShortcodeCheatsheetService} from '../../../services/shortcode-cheatsheet.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class ModalComponent implements OnInit {

  show = false;
  element: HTMLElement;

  @Input() heading: string;
  @Input() showCloseIcon = true;
  @Input() size: 'sm' | 'lg' | 'xl';
  @Input() overflow = false;

  @Output() closeEvent: EventEmitter<null> = new EventEmitter<null>();

  private mouseDownElem;

  constructor(
    private el: ElementRef,
    private modalService: ModalService,
    private shortcodeCheatsheetService: ShortcodeCheatsheetService
  ) {
  }

  ngOnInit() {
    this.modalService.closeAllModalsSubject.subscribe(() => {
      this.close();
    });
    this.element = this.el.nativeElement;
  }

  open() {
    document.body.appendChild(this.element);
    document.body.classList.add('modal-open');
    this.element.classList.add('modal-component-open');
    this.show = true;
  }

  close() {
    this.shortcodeCheatsheetService.hide();
    document.body.classList.remove('modal-open');
    this.element.classList.remove('modal-component-open');
    this.show = false;
    if (this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
    this.closeEvent.emit();
  }

  @HostListener('mousedown', ['$event'])
  mousedownEvent(event) {
    this.mouseDownElem = event;
  }

  @HostListener('mouseup', ['$event'])
  mouseupEvent(event) {
    if (event.target.classList[0] === 'modal' && this.mouseDownElem.target.classList[0] === 'modal') {
      this.close();
    }
  }

}
