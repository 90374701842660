import {Component, ElementRef, OnInit} from '@angular/core';
import {Toast} from '../../../models/toast.interface';
import {ConfettiService} from '../../../services/confetti.service';
import {ToastService} from '../../../services/toast.service';

@Component({
  selector: 'toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {

  toasts: Toast[] = [];
  addEvent: any;
  closeAllEvent: any;

  constructor(
    private toastService: ToastService,
    private confettiService: ConfettiService,
    private el: ElementRef,
  ) {
  }

  close(toast: Toast): void {
    toast.transparent = true;
    setTimeout(() => {
      this.toasts.splice(this.toasts.indexOf(toast), 1);
    }, 1200);
  }

  ngOnInit() {
    this.addEvent = this.toastService.addEvent$.subscribe(toast => {
      this.add(toast);
    });

    this.closeAllEvent = this.toastService.closeAllEvent$.subscribe(() => {
      this.closeAll();
    });
  }

  add(toast: Toast) {
    this.toasts.push(toast);
    if (toast.confetti) {
      setTimeout(() => {
        this.confettiService.fire({
          effect: 'mouse',
          x: this.el.nativeElement.offsetLeft + 150,
          y: this.el.nativeElement.offsetTop + 20
        });
      }, 500);
    }
    if (toast.persistent !== true) {
      setTimeout(() => {
        this.close(toast);
      }, toast.autoClose || 3000);
    }
  }

  closeAll() {
    this.toasts.forEach(toast => {
      this.close(toast);
    });
  }

}
