import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Wedding} from '../../../models/wedding.interface';

@Component({
  selector: 'client-wedding-navbar',
  templateUrl: './client-wedding-navbar.component.html',
  styleUrls: ['./client-wedding-navbar.component.scss'],
})
export class ClientWeddingNavbarComponent {

  @Input() wedding: Wedding;

  environment = environment;
}
