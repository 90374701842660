import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {LoadingService} from '../services/loading.service';
import {WeddingService} from '../services/wedding.service';

@Injectable()
export class WeddingResolver implements Resolve<any> {
  constructor(
    private weddingService: WeddingService,
    private loadingService: LoadingService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    this.loadingService.show();
    return this.weddingService.get(route.paramMap.get('reference').toUpperCase());
  }
}
