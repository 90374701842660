import {Component} from '@angular/core';
import {AuthenticationCoreService} from 'authentication-core';
import {ChatService} from '../../../services/chat.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'live-chat',
  templateUrl: './live-chat.component.html'
})
export class LiveChatComponent {

  initalized = false;
  api;
  script;

  constructor(
    private chatService: ChatService,
    private authenticationCoreService: AuthenticationCoreService,
  ) {
    // this.checkHasRightsToChat();
    // this.chatService.authEvent$.subscribe(() => {
    //   this.checkHasRightsToChat();
    // });
    // this.authenticationCoreService.authenticationChange.subscribe(response => {
    //   if (!response) {
    //     this.hide();
    //   }
    // });
  }

  initalize() {
    // this.initalized = true;
    // this.api = (<any> window).Tawk_API || {};
    // this.script = document.createElement('script');
    // const s0 = document.getElementsByTagName('script')[0];
    // this.script.async = true;
    // this.script.id = 'tawkto';
    // this.script.src = environment.tawkto.script_src;
    // this.script.charset = 'UTF-8';
    // this.script.setAttribute('crossorigin', '*');
    // s0.parentNode.insertBefore(this.script, s0);
  }

  checkHasRightsToChat() {
    this.chatService.get().subscribe(response => {
      if (response.chat_active) {
        if (this.initalized) {
          this.show();
        } else {
          this.initalize();
        }
      } else {
        if (this.initalized) {
          this.hide();
        }
      }
    });
  }

  hide() {
    (<any> window).Tawk_API.hideWidget();
  }

  show() {
    (<any> window).Tawk_API.showWidget();
  }
}
