import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {SliceComponent} from './slice.component';

@Component({
  selector: 'stack',
  templateUrl: './stack.component.html',
  styleUrls: ['./stack.component.scss']
})
export class StackComponent {

  @ContentChildren(SliceComponent) slices !: QueryList<SliceComponent>;

  @Input() id: string;

  closeSlice(index: number) {
    if (index === 0) {
      // Pass up
      return;
    }
    if (index === (this.slices.length - 1)) {
      // Last slice
    }

    this.slices.forEach((slice, i) => {
      if (i === index) {
        // Remove close class
        slice.close();
      }
      if (i === (index - 1)) {
        slice.reveal();
      }
    });
  }

  showSlice(index: number) {
    if (index === (this.slices.length - 1)) {
      // Last slice

      return;
    }

    this.slices.forEach((slice, i) => {
      if (i === index) {
        // Remove close class
        slice.putUnder();
      }
      if (i === (index + 1)) {
        slice.open();
      }
    });
  }

}
