import { Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CurrentUserService } from '../core/services/current-user.service';
import { UserGroupService } from '../services/user-group.service';

@Directive({
  selector: '[userInGroup]'
})
export class UserInGroupDirective {

  private permissions = [];
  private operation_mode = 'AND';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userGroupsService: UserGroupService,
    private currentUserService: CurrentUserService
  ) {
  }

  ngOnInit() {
    this.updateView();
    this.userGroupsService.gotGroups.subscribe((tof)=>{
      if(tof){
        this.updateView();
      }
    })
  }

  @Input()
  set userInGroup(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set userInGroupMode(mode) {
    if (mode) {
      this.operation_mode = mode;
    }
  }

  private updateView() {
    if (this.currentUserService.isUserSet) {
      let mode = this.operation_mode;
      if (this.operation_mode == 'NOT') {
        mode = 'AND'
      }
      let hasPermission = this.userGroupsService.hasPermission(this.permissions, mode);
      if (this.operation_mode == 'NOT') {
        hasPermission = !hasPermission;
      }
      if (hasPermission) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }else{
      this.viewContainer.clear();
    }
  }

}
