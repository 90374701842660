// Core
import {registerLocaleData} from '@angular/common';
// Locale
import localeEn from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';

import * as Sentry from '@sentry/browser';
import {AuthenticationCoreModule} from 'authentication-core';
// Modules
import {StorageCoreModule} from 'storage-core';
import {UserCoreModule} from 'user-core';
import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
// Misc Components
// Forms
// Components
// Containers
// Interceptors
// Pages
// Pipes
// Services
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NotFoundPage } from './modules/legacy/pages/errors/404/404.page';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ChatService } from './services/chat.service';
import { ConfettiService } from './services/confetti.service';
import { ContactSearchStorage } from './services/contact-search-storage.service';
import { LoadingService } from './services/loading.service';
import { ModalService } from './services/modal.service';
import { ShortcodeCheatsheetService } from './services/shortcode-cheatsheet.service';
import { SidebarService } from './services/sidebar.service';
import { ToastService } from './services/toast.service';
import { UserGroupService } from './services/user-group.service';
import { HutReportingCoreModule } from 'hut-ng-reporting-suite';

registerLocaleData(localeEn, 'en', localeEnGB);

// Sentry error handler
Sentry.init({
  dsn: environment.sentry.dsn,
  environment: 'unv-' + (environment.production ? 'prod' : 'test')
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    // Error Pages
    NotFoundPage,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthenticationCoreModule.forRoot({
      apiRoot: environment.api.root + 'rest-auth',
      endSessionReturnUrl: '/login',
      maxInactivePeriodInSeconds: 3600,
      unAuthReturnRouteUrl: 'login',
      warningPeriodInSeconds: 5
    }),
    HutReportingCoreModule.forRoot(environment),
    StorageCoreModule,
    UserCoreModule
  ],
  providers: [
    //Services
    ConfettiService,
    ContactSearchStorage,
    ChatService,
    LoadingService,
    ModalService,
    ShortcodeCheatsheetService,
    SidebarService,
    ToastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}