import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { HutTimelineComponent, HutTimelineModule } from 'hut-timeline';
import { SortablejsModule } from 'ngx-sortablejs';
import { environment } from 'src/environments/environment';
import { DateHelper } from '../helpers/date.helper';
import { IdHelper } from '../helpers/id.helper';
import { ParamsHelper } from '../helpers/params.helper';
import { WeddingResolver } from '../resolvers/wedding.resolver';
import { RouterModule } from '@angular/router';
import { LiveChatComponent } from './components/live-chat/live-chat.component';
import { ModalComponent } from './components/modals/modal.component';
import { ClientWeddingNavbarComponent } from './components/navbar/client-wedding-navbar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SliceComponent } from './components/stack/slice.component';
import { StackComponent } from './components/stack/stack.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import { TaskFormContainer } from './containers/forms/task/task-form.container';
import { PersonalTaskFormContainer } from './containers/forms/personal-task/personal-task-form.container';
import { WeddingSearchInputDropdownContainer } from './containers/search-dropdown-input/wedding/wedding-search-input-dropdown.container';
import { TimeToDatetimePipe } from 'src/app/pipes/time-to-datetime.pipe';
import { PlannerSearchInputDropdownContainer } from './containers/search-dropdown-input/planner/planner-search-input-dropdown.container';
import { ShortcodeCheatsheetComponent } from './components/shortcode-cheatsheet/shortcode-cheatsheet.component';
import { ShortcodeHelpTextComponent } from './components/shortcode-help-text/shortcode-help-text.component';
import { ConfettiComponent } from './components/confetti/confetti.component';
import { DeveloperNoteComponent } from './components/developer-note/developer-note.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridGroupHeaderComponent } from '../modules/reporting/components/grid/ag-grid-group-header/ag-grid-group-header.component';
import { AuthenticationCoreModule } from 'authentication-core';
import { UserInGroupDirective } from '../directives/user-in-group.directive';
import { UserGroupService } from '../services/user-group.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';


@NgModule({
  declarations: [
    // Page Part Components
    ClientWeddingNavbarComponent,
    DatePickerComponent,
    LiveChatComponent,
    ModalComponent,
    NavbarComponent,
    PaginationComponent,
    SidebarComponent,
    SliceComponent,
    StackComponent,
    ToastsComponent,
    ShortcodeCheatsheetComponent,
    ShortcodeHelpTextComponent,
    // Forms
    PersonalTaskFormContainer,
    PlannerSearchInputDropdownContainer,
    TaskFormContainer,
    WeddingSearchInputDropdownContainer,
    //Pipes
    TimeToDatetimePipe,
    //Other
    ConfettiComponent,
    DeveloperNoteComponent,
    LoadingBarComponent,
    //Directives
    UserInGroupDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    FlatpickrModule.forRoot({
      altInput: true,
      altInputClass: 'form-control',
      altFormat: 'd F Y'
    }),
    HttpClientModule,
    NgbModule,
    HutTimelineModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SortablejsModule,
    RouterModule
  ],
  providers: [
    DateHelper,
    IdHelper,
    WeddingResolver,
    ParamsHelper
  ],
  exports: [
    // Page Part Components
    ClientWeddingNavbarComponent,
    DatePickerComponent,
    LiveChatComponent,
    HutTimelineComponent,
    ModalComponent,
    NavbarComponent,
    PaginationComponent,
    SidebarComponent,
    SliceComponent,
    StackComponent,
    ToastsComponent,
    ShortcodeCheatsheetComponent,
    ShortcodeHelpTextComponent,
    //Forms
    PersonalTaskFormContainer,
    PlannerSearchInputDropdownContainer,
    TaskFormContainer,
    WeddingSearchInputDropdownContainer,
    //Other
    ConfettiComponent,
    DeveloperNoteComponent,
    LoadingBarComponent,
    UserInGroupDirective,
    //Imports
    TimeToDatetimePipe,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    FontAwesomeModule,
    FlatpickrModule,
    SortablejsModule,
    AuthenticationCoreModule,
  ]
})
export class SharedModule { }
