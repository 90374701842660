import {ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {IdHelper} from '../../../helpers/id.helper';
import {SearchInputFilter} from '../../../models/search-input-filter.interface';

export abstract class SearchInputDropdownContainer implements OnInit, OnChanges {

  inputForm: FormGroup;
  searchString: string;
  selectedItem: any;
  items: object[] = [];
  itemsTotal: number;
  loading = false;
  showDropdown = false;

  idSuffix: string;

  filters: SearchInputFilter[] = [];

  @Output() selectItemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearItemEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() addItemEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() showClearButton = true;
  @Input() showAddButton = false;
  @Input() divideEachItem = false;
  @Input() error = null;
  @Input() showFilters = false;
  @Input() initialSelectedItem: any;
  @Input() ignoreItems: object[];
  @Input() showDeleteButton = false;
  @Input() showDetailedInformation = true;

  private element: ElementRef;

  private ignoreClickEvent = false;

  protected constructor(
    public idHelper: IdHelper
  ) {
    this._initForm();
    this.idSuffix = this.idHelper.get(10);
  }

  regiserElement(el: ElementRef) {
    this.element = el;
  }

  ngOnInit() {
    this.inputForm.get('searchQuery').valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.search();
    });
    this.inputForm.get('searchQuery').valueChanges.subscribe(value => {
      this.searchString = value;
      this.showDropdown = true;
      this.loading = true;
    });
    this.search();
  }

  ngOnChanges() {
    if (this.initialSelectedItem && !this.error) {
      this.selectItem(this.initialSelectedItem);
    } else if (this.initialSelectedItem && this.error) {
      this.clear();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.ignoreClickEvent) {
      this.ignoreClickEvent = false;
      return;
    }
    this.showDropdown = event.target.closest('#dropdown-' + this.idSuffix) && !this.selectedItem;
  }

  @HostListener('keyup', ['$event'])
  keyup(event) {
    if (this.searchString && event.key === 'Enter') {
      this.addItem();
    }
  }

  handleFilterClick(event, filter, item) {
    this.filters.forEach(f => {
      f.items.forEach(i => {
        if (item.field === i.field && item.value === i.value) {
          i.checked = event.target.checked;
        }
      });
    });
    this.search();
  }

  deleteItem(item: object) {

  }

  _initForm() {
    this.inputForm = new FormGroup({
      searchQuery: new FormControl('')
    });
  }

  addItem() {
    this.ignoreClickEvent = true;
    this.addItemEvent.emit(this.searchString);
    this.searchString = null;
    this.inputForm.get('searchQuery').patchValue('', {emitEvent: false});
    this.showDropdown = false;
  }

  clear() {
    this.ignoreClickEvent = true;
    this.clearItemEvent.emit(null);
    this.showDropdown = false;
    this.selectedItem = null;
    this.searchString = null;
    this.inputForm.get('searchQuery').enable({emitEvent: false});
    this.inputForm.get('searchQuery').patchValue('', {emitEvent: false});
    this.resetFilters();
    this.search();
  }

  reset() {
    this.ignoreClickEvent = true;
    this.showDropdown = false;
    this.selectedItem = null;
    this.inputForm.get('searchQuery').enable({emitEvent: false});
    this.inputForm.get('searchQuery').patchValue('', {emitEvent: false});
  }

  search(): void {
  }

  resetFilters() {
    this.filters.forEach(f => {
      f.items.forEach(i => {
        i.checked = false;
      });
    });
  }

  selectItem(item) {
    this.showDropdown = false;
    this.selectedItem = item;
    this.inputForm.get('searchQuery').disable({emitEvent: false});
    this.inputForm.get('searchQuery').patchValue(this.getItemLabel(item), {emitEvent: false});
  }

  handleSelectItem(item) {
    this.selectItem(item);
    this.selectItemEvent.emit(item);
  }

  getItemLabel(item: object): string {
    return null;
  }

  getItemDetail(item: object): string {
    return null;
  }

  getItemLabelBadges(item: object): string[] {
    return null;
  }

  filtersToUrlParams(): { field, value }[] {
    const checkedFilters = [];
    this.filters.forEach(f => {
      f.items.forEach(i => {
        if (i.checked) {
          checkedFilters.push({field: i.field, value: i.value});
        }
      });
    });
    return checkedFilters;
  }

}
