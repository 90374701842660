import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenCoreService } from 'authentication-core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationCoreInterceptor implements HttpInterceptor {
    protected noAuthPaths: string[];
    protected tokenHeader = 'JWT ';

    constructor(private tokenService: TokenCoreService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.tokenService.setRefreshTokenTimer();
        const idToken = this.tokenService.getToken();
        if (idToken && !req.url.includes('/authentication/login/')) {
            const cloned = req.clone({
                withCredentials: false,
                headers: req.headers.set('Authorization', this.tokenHeader + idToken)
            });
            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}
