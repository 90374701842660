import {Component, OnInit} from '@angular/core';
import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import {LoadingService} from '../../../services/loading.service';

@Component({
  selector: 'loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})

export class LoadingBarComponent implements OnInit {

  faSpinnerThird = faSpinnerThird;
  show = false;

  constructor(
    private loadingBarService: LoadingService,
  ) {
  }

  ngOnInit() {
    this.loadingBarService.loadingEvent.subscribe(show => {
      this.show = show;
    });
  }

}
