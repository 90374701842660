import {Component} from '@angular/core';
import {Shortcode} from '../../../models/shortcode.model';
import {Wedding} from '../../../models/wedding.interface';
import {ShortcodeCheatsheetService} from '../../../services/shortcode-cheatsheet.service';
import {ShortcodeService} from '../../../services/shortcode.service';
import {ClipboardService} from 'ngx-clipboard';
import {AuthenticationCoreService} from 'authentication-core';

@Component({
  selector: 'shortcode-cheatsheet',
  templateUrl: 'shortcode-cheatsheet.component.html',
  styleUrls: ['shortcode-cheatsheet.component.scss']
})
export class ShortcodeCheatsheetComponent {

  shortcodes: Shortcode[];
  weddingId: Wedding['id'];

  copiedShortcode: Shortcode;

  constructor(
      private shortcodeService: ShortcodeService,
      private shortcodeCheatsheetService: ShortcodeCheatsheetService,
      private clipboardService: ClipboardService,
      private authService: AuthenticationCoreService
  ) {
    this.getShortcodes();

    this.shortcodeCheatsheetService.weddingChanged.subscribe(weddingId => {
      this.weddingId = weddingId;
      this.getShortcodes();
    });
  }

  getShortcodes() {
    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.shortcodeService.get(this.weddingId).subscribe(response => {
      this.shortcodes = response.results;
    });
  }

  closeShortcodeCheatsheet() {
    this.shortcodeCheatsheetService.hide();
  }

  copyShortcode(shortcode: Shortcode, element?: Event) {
    this.clipboardService.copyFromContent(shortcode.short_code);
    this.copiedShortcode = shortcode;
    setTimeout(() => {
      this.copiedShortcode = null;
    }, 2000);
  }
}
