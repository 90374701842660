import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'timeStringToDate'})
export class TimeToDatetimePipe implements PipeTransform {
  transform(timeStr: string) {
    if (!timeStr) {
      return;
    }
    const timeParts = timeStr.split(':');
    const d = new Date();
    d.setHours(+timeParts[0] || 0);
    d.setMinutes(+timeParts[1] || 0);
    d.setSeconds(+timeParts[2] || 0);
    return d.toISOString();
  }
}
