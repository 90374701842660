import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {faSortAlt} from '@fortawesome/pro-regular-svg-icons';
import {faTimes} from '@fortawesome/pro-regular-svg-icons/faTimes';
import {AuthenticationCoreService} from 'authentication-core';
import {Event} from '../../../models/event.interface';
import {Wedding} from '../../../models/wedding.interface';
import {ConfettiService} from '../../../services/confetti.service';
import {PersonalTaskService} from '../../../services/personal-task.service';
import {SidebarService} from '../../../services/sidebar.service';
import {TaskService} from '../../../services/task.service';
import {ToastService} from '../../../services/toast.service';
import {ModalComponent} from '../modals/modal.component';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @ViewChild('addTaskModal', {static: true}) addTaskModal: ModalComponent;
  @ViewChild('editTaskModal', {static: true}) editTaskModal: ModalComponent;

  @ViewChild('addWeddingTaskModal', {static: true}) addWeddingTaskModal: ModalComponent;
  @ViewChild('editWeddingTaskModal', {static: true}) editWeddingTaskModal: ModalComponent;

  sidebarOpen = false;
  sidebarDownElem;
  faSortAlt = faSortAlt;
  faTimes = faTimes;

  selectedTab: 'my-tasks' | 'wedding-tasks' = 'my-tasks';

  editingPersonalTask: Event;
  editingWeddingTask: Event;

  tasks: Event[];
  loadingTasks = true;
  tasksWedding: Wedding;
  incompleteTasksOnly = false;
  sortField: string = null;
  sortAsc: boolean;

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private toastService: ToastService,
    private confettiService: ConfettiService,
    private authenticationCoreService: AuthenticationCoreService,
    private weddingTaskService: TaskService,
    private taskService: PersonalTaskService
  ) {
  }

  ngOnInit() {
    this._getTasks();
    this.sidebarService.openEvent$.subscribe(() => {
      this.open();
    });
    this.sidebarService.closeEvent$.subscribe(() => {
      this.close();
    });
    this.sidebarService.toggleSubject$.subscribe(() => {
      this.toggle();
    });
  }

  changeTab(tab: 'my-tasks' | 'wedding-tasks') {
    if (tab === this.selectedTab) {
      return;
    }
    this.tasks = null;
    this.tasksWedding = null;
    this.selectedTab = tab;
    if (tab !== 'wedding-tasks') {
      this.loadingTasks = true;
      this._getTasks();
    }

  }

  sortByField(field: string) {
    if (this.sortField === field) {
      if (this.sortAsc === true) {
        this.sortField = null;
        this.sortAsc = null;
      } else {
        this.sortAsc = !this.sortAsc;
        this.sortField = field;
      }
    } else {
      this.sortAsc = false;
      this.sortField = field;
    }
    this._getTasks();
  }

  setInccompleteOnly(event) {
    this.incompleteTasksOnly = event.target.checked;
    this._getTasks();
  }

  setWedding(wedding: Wedding) {
    this.tasksWedding = wedding;
    this.loadingTasks = true;
    this._getTasks();
  }

  resetWeddingTasks() {
    this.tasks = null;
    this.tasksWedding = null;
  }

  getTabTitle() {
    switch (this.selectedTab) {
      case 'my-tasks':
        return 'My Tasks';
      case 'wedding-tasks':
        return 'My Wedding Tasks';
    }
  }

  handleAddTaskButton() {
    this.close();
    if (this.selectedTab === 'my-tasks') {
      this.addTaskModal.open();
    } else if (this.selectedTab === 'wedding-tasks') {
      this.addWeddingTaskModal.open();
    }
  }

  open() {
    document.body.classList.add('sidebar-open');
    this.sidebarOpen = true;
  }

  close() {
    document.body.classList.remove('sidebar-open');
    this.sidebarOpen = false;
  }

  toggle() {
    if (this.sidebarOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  @HostListener('mousedown', ['$event'])
  mousedownEvent(event) {
    this.sidebarDownElem = event;
  }

  @HostListener('mouseup', ['$event'])
  mouseupEvent(event) {
    if (event.target.classList[0] === 'sidebar-container' && this.sidebarDownElem.target.classList[0] === 'sidebar-container') {
      this.close();
    }
  }

  toggleTaskCompletion(task: Event, element?) {
    const completed = !task.completed;
    if (this.selectedTab === 'my-tasks') {
      this.taskService.patch(task.id, {completed}).subscribe(response => {
        this.toastService.add({title: 'Success', message: 'Task updated successfully'});
        Object.assign(task, response);
        if (completed) {
          this.confettiService.fire({effect: 'targeted', element});
        }
      });
    } else if (this.selectedTab === 'wedding-tasks') {
      this.weddingTaskService.patch(task.id, {completed}).subscribe(response => {
        this.toastService.add({title: 'Success', message: 'Task updated successfully'});
        Object.assign(task, response);
        if (completed) {
          this.confettiService.fire({effect: 'targeted', element});
        }
      });
    }
  }

  handleEditPersonalTaskButton(task: Event) {
    this.editingPersonalTask = task;
    this.close();
    this.editTaskModal.open();
  }

  isLoggedIn() {
    return this.authenticationCoreService.isAuthenticated();
  }

  handleAddPersonalTaskEvent() {
    this.addTaskModal.close();
    this._getTasks();
    this.open();
  }

  handleAddCancelPersonalTaskEvent() {
    this.addTaskModal.close();
    this.open();
  }

  handleEditCancelPersonalTaskEvent() {
    this.editTaskModal.close();
    this.editingPersonalTask = null;
    this.open();
  }

  handleDeletePersonalTaskEvent() {
    this.editTaskModal.close();
    this.editingPersonalTask = null;
    this._getTasks();
    this.open();
  }

  handleEditPersonalTaskEvent() {
    this.editTaskModal.close();
    this.editingPersonalTask = null;
    this._getTasks();
    this.open();
  }

  private _getTasks() {
    this.loadingTasks = true;
    const params = [];
    if (this.incompleteTasksOnly === true) {
      params.push({field: 'completed', value: false});
    }

    // TODO: Change event_types to letters
    if (this.selectedTab === 'my-tasks') {
      this.taskService.get(this.sortField, this.sortAsc, params).subscribe(response => {
        this.tasks = response.results;
        this.loadingTasks = false;
      });
    } else if (this.selectedTab === 'wedding-tasks') {
      params.push({field: 'event_type', value: 7});
      this.weddingTaskService.get((this.tasksWedding ? this.tasksWedding.id : null), this.sortField, this.sortAsc, params).subscribe(response => {
        this.tasks = response.results;
        this.loadingTasks = false;
      });
    }

  }

}
