import {LoginDetails} from 'authentication-core';
import { UserBase } from 'user-core';

export interface LoginRequest extends LoginDetails {
  email: User['email'];
}

export interface LoginResponse {
  token: string;
  user: User;
}

export class User extends UserBase {
  email: string;
  first_name: string;
  last_name: string;
  pk: number;
  username: string;
  contact: number;
}