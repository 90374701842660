import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input() collectionSize: number;
  @Input() pageSize: number;
  @Input() currentPage: number;
  @Input() lastPage: boolean;
  @Input() disable = true;

  @Output() nextPressedEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() previousPressedEvent: EventEmitter<null> = new EventEmitter<null>();

  collectionPageTotal: number;

  ngOnChanges() {
    this.collectionPageTotal = Math.ceil(this.collectionSize / this.pageSize);
  }

  nextPress() {
    this.nextPressedEvent.emit();
  }

  previousPress() {
    this.previousPressedEvent.emit();
  }

}
