import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfettiSettings} from '../models/confetti.interface';

@Injectable({
  providedIn: 'root'
})

export class ConfettiService {

  private fireEvent = new Subject<ConfettiSettings>();
  fireEvent$ = this.fireEvent.asObservable();

  fire(settings: ConfettiSettings) {
    this.fireEvent.next(settings);
  }
}
