import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ChatActiveResponse} from '../models/chat.interface';
import {Toast} from '../models/toast.interface';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  url = environment.api.client.chat;

  private subject = new Subject();
  authEvent$ = this.subject.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }

  get(): Observable<ChatActiveResponse> {
    return this.http.get<ChatActiveResponse>(this.url.get.uri, {withCredentials: this.url.get.protected});
  }

  authChangeEvent(){
    this.subject.next();
  }

}
