import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {

  private openSubject = new Subject<null>();
  openEvent$ = this.openSubject.asObservable();

  private closeSubject = new Subject<null>();
  closeEvent$ = this.closeSubject.asObservable();

  private toggleSubject = new Subject<null>();
  toggleSubject$ = this.toggleSubject.asObservable();

  open() {
    this.openSubject.next();
  }

  close() {
    this.closeSubject.next();
  }

  toggle() {
    this.toggleSubject.next();
  }
}
