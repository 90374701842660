import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Wedding} from '../models/wedding.interface';

@Injectable({
  providedIn: 'root'
})
export class ShortcodeCheatsheetService {

  visibilityChanged = new Subject<boolean>();
  weddingChanged = new Subject<Wedding['id']>();

  show() {
    this.visibilityChanged.next(true);
  }

  hide() {
    this.visibilityChanged.next(false);
  }

  setWedding(weddingId: Wedding['id']) {
    this.weddingChanged.next(weddingId);
  }

}
