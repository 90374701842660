import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Event, EventListResponse, EventPostRequest, EventPutRequest} from '../models/event.interface';

@Injectable({
  providedIn: 'root'
})
export class PersonalTaskService {

  url = environment.api.planner.task;

  constructor(
    private http: HttpClient,
  ) {
  }

  get(orderField?: string, orderAsc?: boolean, params: { field, value }[] = []): Observable<EventListResponse> {
    let url = this.url.list.uri;

    if (orderField !== null) {
      if (orderAsc === false) {
        params.push({field: 'ordering', value: `-${orderField}`});
      } else {
        params.push({field: 'ordering', value: `${orderField}`});
      }
    }

    if (params && params.length > 0) {
      url += '?' + params.map(p => p.field + '=' + p.value).join('&');
    }

    return this.http.get<EventListResponse>(
      url,
      {withCredentials: this.url.get.protected}
    );
  }

  add(data: EventPostRequest): Observable<EventPostRequest> {
    return this.http.post<EventPostRequest>(this.url.post.uri, data, {withCredentials: this.url.post.protected});
  }

  update(id: Event['id'], data: EventPostRequest): Observable<EventPostRequest> {
    return this.http.put<EventPostRequest>(
      this.url.put.uri.replace('{ID}', id.toString()),
      data,
      {withCredentials: this.url.put.protected});
  }

  delete(id: Event['id']) {
    return this.http.delete(this.url.delete.uri.replace('{ID}', id.toString()), {withCredentials: this.url.delete.protected});
  }

  patch(id: Event['id'], data: EventPutRequest): Observable<EventPutRequest> {
    return this.http.patch<EventPutRequest>(
      this.url.patch.uri.replace('{ID}', id.toString()),
      data,
      {withCredentials: this.url.patch.protected});
  }
}
