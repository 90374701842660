import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AuthenticationCoreService } from 'authentication-core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { StorageCoreService } from 'storage-core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user.interface';
import { CurrentUserCoreService } from 'user-core';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService extends CurrentUserCoreService implements OnDestroy {

    protected getApiRoot = () => `${environment.api.root}rest-auth/user/`;

    private subscriptions = new Subscription();

    private isAuthenticated = false;
    private waitingOnRequests = false;
    public isUserSet = false;

    private userSubject = new BehaviorSubject<User>(null);
    private userSubject$ = this.userSubject.asObservable();

    constructor(
        protected http: HttpClient,
        protected storageService: StorageCoreService,
        protected authenticationService: AuthenticationCoreService,
    ) {
        super(http, storageService, environment);

        this.isAuthenticated = this.authenticationService.isAuthenticated();
        if (this.isAuthenticated) {
            this.getAndSetUser();
        }

        this.subscriptions.add(this.hasUpdated().pipe(
            flatMap(() => this.get())
        ).subscribe((user) => this.setUser(user)));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getAndSetUser() {
        this.waitingOnRequests = true;
        this.get().subscribe((user) => {
            this.setUser(user);
            this.isUserSet = true;
            this.waitingOnRequests = false;
        }, (error) => this.waitingOnRequests = false);
    }

    private setUser = (user: any) => this.userSubject.next(plainToClass(User, user as object));

    getUser(): Observable<User> {
        this.isAuthenticated = this.authenticationService.isAuthenticated();
        if (!this.isAuthenticated) {
            this.isUserSet = false;
        }

        if (this.isAuthenticated && !this.waitingOnRequests && !this.isUserSet) {
            this.getAndSetUser();
        }

        return this.userSubject$;
    }

    login(credentials: any) {
        this.isUserSet = false;
        return this.authenticationService.login(credentials);
    }

    logOut() {
        this.isUserSet = false;
        return this.authenticationService.logout();
    }
}
