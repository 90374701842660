import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { Event } from 'src/app/models/event.interface';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit, OnChanges {

  form: FormGroup;

  @Input() errors = {} as any;
  @Input() useTime = false;
  @Input() eventInstance: Event;
  @Input() submitAttempted: boolean;

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      start_date: new FormControl(null, Validators.required),
      end_date: new FormControl(null, Validators.required),
      start_time: new FormControl(null),
      end_time: new FormControl(null)
    }, { validators:  this.endDateValidation });
  }

  endDateValidation: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
    const startDate = formGroup.get('start_date').value;
    const endDate = formGroup.get('end_date').value;

    if (this.submitAttempted && (startDate === null || startDate > endDate)) {
      this.errors['end_date'] = 'Please make sure End Date is later than Start Date';
      return { end_date: true };
    }
    this.errors['end_date'] = null;
    return null;
  };

  setMinEndDate() {
    const startDate = this.form.get('start_date').value;
    const endDate = this.form.get('end_date').value;
    if (endDate === null || startDate > endDate) {
      this.form.get('end_date').setValue(startDate);
    }
  }

  ngOnChanges() {
    if (this.eventInstance) {
      this.form.patchValue({
        start_date: this.eventInstance.start_date,
        start_time: this.eventInstance.start_time,
        end_date: this.eventInstance.end_date,
        end_time: this.eventInstance.end_time,
      });
    }
  }

  isValid() {
    return this.form.valid;
  }

  getStartDateString() {
    return this.form.get('start_date').value || null;
  }

  getStartTimeString() {
    return this.form.get('start_time').value || null;
  }

  getEndDateString() {
    return this.form.get('end_date').value || null;
  }

  getEndTimeString() {
    return this.form.get('end_time').value || null;
  }

  reset() {
    this.form.reset();
  }

}
