import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationCoreGuard, SessionCoreInterceptor } from 'authentication-core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { AuthenticationCoreInterceptor } from './interceptors/AuthenticationCoreUNV.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthenticationCoreGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationCoreInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionCoreInterceptor,
            multi: true
        }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
      throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
