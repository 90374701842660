import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Contact} from '../../../../models/contact.interface';
import {Event} from '../../../../models/event.interface';
import {Planner} from '../../../../models/planner.interface';
import {Wedding} from '../../../../models/wedding.interface';
import {TaskService} from '../../../../services/task.service';
import {ToastService} from '../../../../services/toast.service';
import {PlannerSearchInputDropdownContainer} from '../../search-dropdown-input/planner/planner-search-input-dropdown.container';
import {FormContainer} from '../form.container';

@Component({
  selector: 'task-form',
  templateUrl: './task-form.container.html'
})

export class TaskFormContainer extends FormContainer implements OnChanges {

  @Input() wedding: Wedding;
  @Input() instance: Event;

  @ViewChild('ownerSelection', {static: true}) ownerSelection: PlannerSearchInputDropdownContainer;

  priority = 2;

  selectedOwner: Planner | Contact;

  weddingDate: string;

  constructor(
    private toastService: ToastService,
    private taskService: TaskService,
  ) {
    super();
  }

  cleanupForm() {
    this.ownerSelection.clear();
    super.cleanupForm();
    this.weddingDate = moment(this.wedding.date).format('YYYY-MM-DD');
    this.form.patchValue({
      end_date: this.weddingDate
    });
  }

  ngOnChanges() {
    if (this.wedding) {
      this.weddingDate = moment(this.wedding.date).format('YYYY-MM-DD');
    }
    if (this.instance) {
      if (this.wedding && this.instance) {
        this.selectedOwner = this.instance.owner;
        this.priority = this.instance.priority;
        this.form.get('title').patchValue(this.instance.title);
        this.form.get('summary').patchValue(this.instance.summary);
        this.form.get('details').patchValue(this.instance.details);
        this.form.get('priority').patchValue(this.instance.priority);
        this.form.get('end_date').patchValue(this.instance.end_date);
        this.form.get('end_time').patchValue(this.instance.end_time);
        this.form.get('responsible').patchValue(this.instance.responsible);
        this.form.get('completed').patchValue(this.instance.completed);
      }
    }
  }

  initForm() {
    this.form = new FormGroup({
      title: new FormControl(null),
      summary: new FormControl(null),
      details: new FormControl(null),
      priority: new FormControl(null),
      end_date: new FormControl(null, [Validators.required]),
      end_time: new FormControl(null),
      responsible: new FormControl(null),
      completed: new FormControl(null)
    });
  }

  addItem() {
    if (!this.form.valid) {
      return;
    }
    this.taskService.add({
      title: this.form.get('title').value,
      summary: this.form.get('summary').value,
      details: this.form.get('details').value,
      owner: this.selectedOwner ? this.selectedOwner.id : null,
      related_wedding: this.wedding ? this.wedding.id : null,
      // TODO: Change to letter format once implemented in backend
      event_type: ['7'],
      status: this.wedding ? this.wedding.status : null,
      end_date: this.form.get('end_date').value || null,
      end_time: this.form.get('end_time').value || null,
      priority: this.priority,
      completed: this.form.get('completed').value === true,
      responsible: null
    }).subscribe(() => {
      this.toastService.add({title: 'Success', message: 'Task added successfully'});
      super.addItem();
    }, error => {
      this.errors = error.error;
    });
  }

  editItem() {
    if (!this.form.valid) {
      return;
    }
    this.taskService.update(this.instance.id, {
      title: this.form.get('title').value,
      summary: this.form.get('summary').value,
      details: this.form.get('details').value,
      owner: this.selectedOwner ? this.selectedOwner.id : null,
      related_wedding: this.wedding ? this.wedding.id : null,
      // TODO: Change to letter format once implemented in backend
      event_type: ['7'],
      status: this.wedding ? this.wedding.status : null,
      end_date: this.form.get('end_date').value || null,
      end_time: this.form.get('end_time').value || null,
      priority: this.priority,
      completed: this.form.get('completed').value === true,
      responsible: null
    }).subscribe(() => {
      this.toastService.add({title: 'Success', message: 'Task updated successfully'});
      super.editItem();
    }, error => {
      this.errors = error.error;
    });
  }

  deleteItem() {
    if (window.confirm('Are you sure you want to delete this task?')) {
      this.taskService.delete(this.instance.id).subscribe(() => {
        this.toastService.add({title: 'Success', message: 'Table deleted successfully'});
        super.deleteItem();
      });
    }
  }

  ownerSelectEvent(owner: Planner) {
    this.selectedOwner = owner;
  }

  ownerClearEvent() {
    this.selectedOwner = null;
  }

  handleWeddingClearEvent() {
    this.wedding = null;
  }

  handleWeddingSelectEvent(event: Wedding) {
    this.wedding = event;
  }

}
