import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AuthenticationCoreGuard } from 'authentication-core';
import {NotFoundPage} from './modules/legacy/pages/errors/404/404.page';

const routes: Routes = [
  {
    path: 'reports',
    loadChildren: () =>
        import('./modules/reporting/reporting.module').then(m => m.ReportingModule),
    data: { title: 'Reports' }
  },
  {
    path: 'report-exports',
    loadChildren: () =>
        import('./modules/report-exports/report-exports.module').then(m => m.ReportExportsModule),
    data: { title: 'Export Reports' }
  },
  {
    path: '',
    loadChildren: () =>
        import('./modules/legacy/legacy.module').then(m => m.LegacyModule),
    data: { title: null }
  },
  {
    path: 'unavailable-dates',
    loadChildren: () =>
        import('./modules/unavailable-dates/unavailable-dates.module').then(m => m.UnavailableDatesModule),
    data: { title: 'Unavailable Dates' }
  },
  {
    path: '**',
    component: NotFoundPage
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
