import { Component, OnInit, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { faTreeChristmas } from '@fortawesome/pro-regular-svg-icons';
import { AuthenticationCoreService } from 'authentication-core';
import { Observable } from 'rxjs';
import { CurrentUserService } from 'src/app/core/services/current-user.service';
import { User } from 'src/app/models/user.interface';
import { UserCoreService } from 'user-core';
import { environment } from '../../../../environments/environment';
import { UserGroups } from '../../../models/user-group.interface';
import { ChatService } from '../../../services/chat.service';
import { SidebarService } from '../../../services/sidebar.service';
import { ToastService } from '../../../services/toast.service';
import { UserGroupService } from '../../../services/user-group.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  collapsedNavbarShow = false;
  environment = environment;
  user: User;
  isLoggedIn = false;

  constructor(
    private authenticationCoreService: AuthenticationCoreService,
    private router: Router,
    private toastService: ToastService,
    private sidebarService: SidebarService,
    public currentUserService: CurrentUserService,
    private userGroupService: UserGroupService,
    private chatService: ChatService
  ) {

  }

  ngOnInit() {
    this.getUser();
    this.authenticationCoreService.authenticationChange.subscribe(() => {
      this.getUser();
    });
  }

  getUser() {
    this.user = null;
    this.currentUserService.getUser().subscribe((user)=>{
      if(user){
        this.user = user;
        this.isLoggedIn = true;
      }
    });
  }

  logout() {
    this.currentUserService.logOut().subscribe(() => {
      this.toastService.add({ title: 'Success', message: 'You have been logged out' });
      this.userGroupService.clear();
      this.router.navigate(['/login']);
    });
  }

  toggleCollapsedNavbar() {
    this.collapsedNavbarShow = !this.collapsedNavbarShow;
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

}
