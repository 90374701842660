import * as moment from 'moment';

export class DateHelper {

  now: Date;

  constructor() {
    this.set();
  }

  set() {
    this.now = new Date();
  }

  getISODate() {
    this.set();
    return this.now.toISOString();
  }

  getDate(withTime = false) {
    this.set();
    if (withTime) {
      return moment().format('YYYY-MM-DD hh:mm:ss');
    } else {
      return moment().format('YYYY-MM-DD');
    }
  }

  getTime(seconds = false) {
    this.set();
    const timeParts = [('0' + this.now.getHours()).slice(-2), ('0' + this.now.getMinutes()).slice(-2)];
    if (seconds) {
      timeParts.push(('0' + this.now.getSeconds()).slice(-2));
    }
    return timeParts.join(':');
  }

}
