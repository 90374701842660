import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ShortcodeListResponse} from '../models/shortcode.model';
import {Wedding} from '../models/wedding.interface';

@Injectable({
  providedIn: 'root'
})
export class ShortcodeService {

  url = environment.api.shortcode;

  constructor(
    private http: HttpClient
  ) {
  }

  get(weddingId?: Wedding['id']): Observable<ShortcodeListResponse> {
    let url = this.url.list.uri;
    if (weddingId) {
      url += '?related_wedding=' + weddingId;
    }
    return this.http.get<ShortcodeListResponse>(url, {withCredentials: this.url.list.protected});
  }

}
