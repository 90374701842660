import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Toast} from '../models/toast.interface';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  private subject = new Subject<Toast>();
  addEvent$ = this.subject.asObservable();

  private closeSubject = new Subject<Toast>();
  closeAllEvent$ = this.closeSubject.asObservable();

  add(toast: Toast): void {
    if (!Array.isArray(toast.message)) {
      toast.message = [toast.message];
    }
    this.subject.next(Object.assign({}, {
      show: true,
      persistent: false,
      confetti: false,
      autoClose: 5000
    }, toast));
  }

  closeAll() {
    this.closeSubject.next();
  }
}
