// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '1.5.5',
  production: false,
  google_maps: {
    url: 'https://www.google.com/maps/embed/v1/place?q={QUERY}&key={APIKEY}&maptype={MAPTYPE}',
    apiKey: 'AIzaSyA1OrENfRl9cx76h_qJ5MpSNX6-Sh9cY1I',
  },
  sentry: {
    dsn: ''
  },
  tawkto: {
    script_src: ''
  },
  apiRoot: 'https://weddingevent-api-test.herokuapp.com/api/v1/',
  api: {
    root: 'https://weddingevent-api-test.herokuapp.com/',
    authentication: {
      login: {
        post: {uri: 'https://weddingevent-api-test.herokuapp.com/rest-auth/login/', protected: false},
      },
      user: {
        get: {uri: 'https://weddingevent-api-test.herokuapp.com/rest-auth/user/', protected: true},
      },
      usergroups: {
        get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/auth/user/groups/', protected: true},
      },
      password: {
        reset: {
          post: {uri: 'https://weddingevent-api-test.herokuapp.com/rest-auth/password/reset/', protected: false},
          confirm: {
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/rest-auth/password/reset/confirm/',
              protected: false
            },
          }
        },
        change: {
          post: {uri: 'https://weddingevent-api-test.herokuapp.com/rest-auth/password/change/', protected: true}
        }
      }
    },
    client: {
      chat: {
        get: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/chat/ischatactive/',
          protected: true
        }
      },
      wedding: {
        get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings', protected: true},
        form: {
          items: {
            get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/clientweddingformitems/', protected: true}
          },
          sections: {
            get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/clientweddingformsections/', protected: true}
          }
        },
        changerequests: {
          get: { uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingchangerequests/', protected: true },
          delete: { uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingchangerequests/{ID}/', protected: true },
          notes: {
            get: { uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingchangerequestnotes/', protected: true },
            delete: { uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingchangerequestnotes/{ID}/', protected: true },
          }
        },
        guests: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/guests/?ordering=surname&page_size=1000',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/guests/add/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/guests/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/guests/{ID}/',
            protected: true
          },
        },
        meals: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/meals/',
            protected: true
          },
        },
        roombookings: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/roombookings/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/roombookings/guests/add/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/clients/api/wedding/roombookings/guests/{ID}/',
            protected: true
          },
        }
      },
    },
    planner: {
      report_exports: {
        export: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/reports/reports/{ID}/generate/',
            protected: true
          }
        },
        list: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/reports/reports/',
            protected: true
          }
        }
      },
      wedding: {
        datatables: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings/?format=datatables',
          protected: true
        },
        list: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings/', protected: true},
        post: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings/', protected: true},
        get: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings/{REFERENCE}/',
          protected: true
        },
        put: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings/{ID}/',
          protected: true
        },
        delete: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddings/{ID}/',
          protected: true
        },
        weddingform: {
          section: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformsections/',
              protected: true
            },
            one: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformsections/{ID}/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformsections/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformsections/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformsections/{ID}/',
              protected: true
            },
            order: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformsections/set_order/',
              protected: true
            },
          },
          item: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformitems/',
              protected: true
            },
            one: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformitems/{ID}/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformitems/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformitems/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformitems/{ID}/',
              protected: true
            },
            order: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddingform/weddingformitems/set_order/',
              protected: true
            },
          }
        },
        accommodation: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/accommodation/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/accommodation/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/accommodation/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/accommodation/{ID}/',
            protected: true
          },
          roomgroupings:{
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roomgroupings/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roomgroupings/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roomgroupings/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roomgroupings/{ID}/',
              protected: true
            },
          },
          roombookings:{
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roombookings/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roombookings/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roombookings/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/roombookings/{ID}/',
              protected: true
            },
          }
        },
        clientcosts: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcostsclient/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcostsclient/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcostsclient/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcostsclient/{ID}/',
            protected: true
          },
        },
        ceremony: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/ceremonies/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/ceremonies/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/ceremonies/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/ceremonies/{ID}/',
            protected: true
          }
        },
        guesttotals: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingguesttotals/',
            protected: true
          },
        }
      },
      ceremony: {
        list: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/ceremonies/ceremonies/',
          protected: true
        },
        locations: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/ceremonies/ceremonylocations/',
            protected: true
          },
          single: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/ceremonies/ceremonylocations/{ID}/',
            protected: true
          }
        }
      },
      contact: {
        datatables: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/contacts/?format=datatables',
          protected: true
        },
        list: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/contacts/', protected: true},
        get: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/contacts/{ID}/',
          protected: true
        },
        put: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/contacts/{ID}/',
          protected: true
        },
        post: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/contacts/', protected: true},
        roles: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/contactroles/',
            protected: true
          },
        },
        address: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/addresses/',
            protected: true
          },
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/addresses/{ID}/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/addresses/{ID}/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/contacts/addresses/',
            protected: true
          },
        }
      },
      guest: {
        get: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guests/',
          protected: true
        },
        put: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guests/{ID}/',
          protected: true
        },
        delete: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guests/{ID}/',
          protected: true
        },
        post: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guests/',
          protected: true
        },
        roles: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guestroles/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guestroles/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guestroles/{ID}/',
            protected: true
          }
        },
        guesttables: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guesttables/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guesttables/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guesttables/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/guests/guesttables/{ID}/',
            protected: true
          }
        }
      },
      layouts: {
        tables: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/layouts/tables/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/layouts/tables/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/layouts/tables/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/layouts/tables/{ID}/',
            protected: true
          }
        }
      },
      leads: {
        sources: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/leadsource/',
            protected: true
          }
        },
        list: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/leads/',
          protected: true
        },
        one: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/leads/{ID}/',
          protected: true
        },
        timeline: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/leads/{ID}/viewing_timeline/',
          protected: true
        },
        notes: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/leadnotes/',
            protected: true
          },
          one: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/leadnotes/{ID}/',
            protected: true
          },
        },
        viewings: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/viewings/',
            protected: true
          },
          one: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/viewings/{ID}/',
            protected: true
          },
          notes: {
            list: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/viewingnotes/',
              protected: true
            },
            one: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/leads/viewingnotes/{ID}/',
              protected: true
            },
          },
        }
      },
      package: {
        accommodation_package_set: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/packages/accommodationpackagesets/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/packages/accommodationpackagesets/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/packages/accommodationpackagesets/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/packages/accommodationpackagesets/{ID}/',
            protected: true
          },
        },
        accommodation_package: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/packages/accommodationpackage/',
            protected: true
          }
        },
        types: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/packages/packagetypes/',
            protected: true
          }
        }
      },
      venue: {
        list: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/venues/', protected: true},
        datatables: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/venues/?format=datatables',
          protected: true
        },
        availability: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/venues/venues/{ID}/availability/',
            protected: true
          }
        }
      },
      event: {
        public_calendar: {
          uri: 'https://weddingevent-api-test.herokuapp.com/D420941B24E7ECA15025012A48B6410E/',
          protected: false
        },
        list: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/events/', protected: true},
        get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/events/{ID}/', protected: true},
        post: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/events/', protected: true},
        put: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/events/{ID}/', protected: true},
        delete: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/events/{ID}/',
          protected: true
        },
        types: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/eventtypes/',
            protected: true
          },
        },
        notes: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/notes/{ID}/',
            protected: true
          },
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/notes/',
            protected: true
          },
          types: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/notetypes/{ID}/',
              protected: true
            },
            list: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/events/notetypes/',
              protected: true
            }
          }
        }
      },
      task: {
        list: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/tasks/tasks/', protected: true},
        get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/tasks/tasks/{ID}/', protected: true},
        post: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/tasks/tasks/', protected: true},
        put: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/tasks/tasks/{ID}/', protected: true},
        patch: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/tasks/tasks/{ID}/', protected: true},
        delete: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/tasks/tasks/{ID}/', protected: true},
      },
      weddingtask: {
        list: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingtasks/',
          protected: true
        },
        get: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingtasks/{ID}/',
          protected: true
        },
        post: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingtasks/',
          protected: true
        },
        put: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingtasks/{ID}/',
          protected: true
        },
        patch: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingtasks/{ID}/',
          protected: true
        },
        delete: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingtasks/{ID}/',
          protected: true
        },
      },
      journal: {
        items: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journals/',
            protected: true
          },
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journals/{ID}/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journals/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journals/{ID}/',
            protected: true
          },
          patch: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journals/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journals/{ID}/',
            protected: true
          },
        },
        categories: {
          list: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journalcategories/',
            protected: true
          },
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journalcategories/{ID}/',
            protected: true
          },
          post: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journalcategories/',
            protected: true
          },
          put: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journalcategories/{ID}/',
            protected: true
          },
          patch: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journalcategories/{ID}/',
            protected: true
          },
          delete: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/journals/journalcategories/{ID}/',
            protected: true
          },
        }
      },
      checklist: {
        list: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/checklists/checklists/',
          protected: true
        },
        get: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/checklists/checklists/{ID}/',
          protected: true
        },
        post: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/checklists/checklists/',
          protected: true
        },
        put: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/checklists/checklists/{ID}/',
          protected: true
        },
        patch: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/checklists/checklists/{ID}/',
          protected: true
        },
        delete: {
          uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/checklists/checklists/{ID}/',
          protected: true
        },
      },
      financials: {
        costtypes: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/financials/costtypes/',
            protected: true
          },
        },
        unittypes: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/financials/unittypes/',
            protected: true
          },
        },
      },
      catering: {
        dietry_attributes: {
          get: {
            uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/catering/dietryattributes/',
            protected: true
          },
        },
        menus: {
          get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/catering/menus/', protected: true},
        },
        meals: {
          get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/catering/meals/', protected: true},
          weddingmeal: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcatering/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingmeals/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingmeals/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingmeals/{ID}/',
              protected: true
            },
          }
        },
        courses: {
          get: {uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/catering/courses/', protected: true},
          courseoptions: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/catering/courseoptions/',
              protected: true
            },
            datatables: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/catering/courseoptions/?format=datatables',
              protected: true
            },
          },
          weddingcourse: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcourses/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcourses/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcourses/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingcourses/{ID}/',
              protected: true
            },
          }
        },
        dishes: {
          weddingdish: {
            get: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingdishes/',
              protected: true
            },
            post: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingdishes/',
              protected: true
            },
            put: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingdishes/{ID}/',
              protected: true
            },
            delete: {
              uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/weddings/weddingdishes/{ID}/',
              protected: true
            },
          }
        }
      }
    },
    shortcode: {
      list: {
        uri: 'https://weddingevent-api-test.herokuapp.com/api/v1/documentation/shortcodes/',
        protected: true
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

