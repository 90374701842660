import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Modal, ModalVisibilityState} from '../models/modal.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modals: Modal[] = [];

  modalSubject = new Subject<ModalVisibilityState>();
  closeAllModalsSubject = new Subject<null>();

  add(modal: Modal) {
    this.modals.push(modal);
  }

  open(modal: Modal) {
    this.modalSubject.next({modal, show: true});
  }

  close(modal: Modal) {
    this.modalSubject.next({modal, show: true});
  }

  closeAll() {
    this.closeAllModalsSubject.next();
  }
}
