import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {AuthenticationCoreService} from 'authentication-core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'developer-note',
  templateUrl: './developer-note.component.html',
  styleUrls: ['./developer-note.component.scss']
})
export class DeveloperNoteComponent implements OnInit {

  inDevelopment: boolean;
  hide = false;

  @Input() showUnauthd = false;

  constructor(
    private el: ElementRef,
    private authenticationCoreService: AuthenticationCoreService,
  ) {
    this.inDevelopment = environment.production === false;
  }

  ngOnInit() {
    if (!this.authenticationCoreService.isAuthenticated() && this.showUnauthd) {
      return;
    }
    if (localStorage.getItem('showDevNotes') === null || environment.production === true || !this.authenticationCoreService.isAuthenticated()) {
      this.el.nativeElement.remove();
    }
  }

}
