import {Component, Input} from '@angular/core';
import {ShortcodeCheatsheetService} from '../../../services/shortcode-cheatsheet.service';

@Component({
  selector: 'shortcode-help-text',
  templateUrl: 'shortcode-help-text.component.html'
})
export class ShortcodeHelpTextComponent {

  show = false;

  constructor(
    private shortCodeCheatsheetService: ShortcodeCheatsheetService
  ) {
    this.shortCodeCheatsheetService.visibilityChanged.subscribe(visibility => {
      this.show = visibility;
      this.updateClass();
    });
  }

  toggleShortcodeCheatsheet() {
    this.updateClass();
    if (this.show) {
      this.shortCodeCheatsheetService.hide();
    } else {
      this.shortCodeCheatsheetService.show();
    }
  }

  updateClass() {
    if (this.show) {
      document.body.classList.add('cheatsheet-open');
    } else {
      document.body.classList.remove('cheatsheet-open');
    }
  }

}
