import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {Contact, ContactSearchResponse} from '../models/contact.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  url = environment.api.planner.contact;

  constructor(
    private http: HttpClient
  ) {
  }

  search(query: string, page: number, orderField?: string, orderAsc?: boolean, params?: { field, value }[]): Observable<ContactSearchResponse> {
    let url = `${this.url.list.uri}?fsearch=${query}&page=${page}`;
    if (orderField !== null) {
      if (orderAsc === false) {
        orderField = `-${orderField}`;
      }
      url += `&ordering=${orderField}`;
    }
    if (params && params.length > 0) {
      url += '&' + params.map(p => p.field + '=' + p.value).join('&');
    }
    return this.http.get<ContactSearchResponse>(url, {withCredentials: this.url.list.protected});
  }

  get(id: Contact['id']): Observable<Contact> {
    return this.http.get<Contact>(`${this.url.get.uri.replace('{ID}', id.toString())}`, {withCredentials: this.url.get.protected});
  }

  delete(contact: Contact) {
    return this.http.delete(this.url.get.uri.replace('{ID}', contact.id.toString()), {withCredentials: this.url.get.protected});
  }

  create(data: object): Observable<Contact> {
    return this.http.post<Contact>(this.url.post.uri, data, {withCredentials: this.url.post.protected});
  }

  update(contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(
      this.url.put.uri.replace('{ID}', contact.id.toString()),
      contact,
      {withCredentials: this.url.put.protected});
  }
}
