import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ConfettiSettings} from '../../../models/confetti.interface';
import {ConfettiService} from '../../../services/confetti.service';

@Component({
  selector: 'confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['confetti.component.scss']
})
export class ConfettiComponent implements OnInit {

  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;

  effect: string;

  ctx: CanvasRenderingContext2D;
  cx;
  cy;
  confettiBits = [];

  sourceX;
  sourceY;

  confettiCount = 300;
  gravity = 1.25;
  terminalVelocity = 7;
  drag = 0.075;
  complete = true;
  colors = [{front: '#E2A198', back: '#EB9488'},
    {front: '#414142', back: '#373737'},
    {front: '#606060', back: '#515152'},
    {front: '#DADADA', back: '#606060'}];

  constructor(
    private confettiService: ConfettiService,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  resizeWindow() {
    this.canvas.nativeElement.width = window.innerWidth;
    this.canvas.nativeElement.height = window.innerHeight;
    this.cx = (this.ctx.canvas.width / 2);
    this.cy = (this.ctx.canvas.height / 2) + 20;
  }

  randomRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  ngOnInit() {
    this.confettiService.fireEvent$.subscribe((settings: ConfettiSettings) => {
      this.effect = settings.effect;
      if (settings.effect === 'targeted') {
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.sourceX = settings.element.getBoundingClientRect().left + scrollLeft + (settings.element.offsetWidth / 2);
        this.sourceY = settings.element.getBoundingClientRect().top + scrollTop + (settings.element.offsetHeight / 2);
      } else if (settings.effect === 'mouse') {
        this.sourceX = settings.x;
        this.sourceY = settings.y;
      }

      this.blastConfetti();
    });
    this.createCanvas();
    this.doRender();

  }

  resetSettings() {
    this.confettiCount = 300;
    this.gravity = 1.25;
    this.terminalVelocity = 7;
    this.drag = 0.075;
  }

  createCanvas() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.canvas.nativeElement.width = window.innerWidth;
    this.canvas.nativeElement.height = window.innerHeight;
    this.cx = (this.ctx.canvas.width / 2);
    this.cy = (this.ctx.canvas.height / 2) + 20;

  }

  initConfetti() {
    this.resetSettings();
    if (this.effect === 'targeted' || this.effect === 'mouse') {
      this.confettiCount = 50;
      this.gravity = 1.5;
      this.terminalVelocity = 6;
      this.drag = 0.1;
    }

    let xDimension = null;
    switch (this.effect) {
      case 'left':
        xDimension = 0;
        break;
      case 'right':
        xDimension = this.canvas.nativeElement.width;
        break;
      case 'leftright':
        xDimension = 0;
        break;
      case 'cover':
        xDimension = null;
        break;
    }

    this.complete = false;
    for (let i = 0; i < this.confettiCount; i++) {
      this.confettiBits.push({
        color: this.colors[Math.floor(this.randomRange(0, this.colors.length))],
        dimensions: {
          x: this.randomRange(10, 10),
          y: this.randomRange(20, 20),
        },
        position: {
          x:
            (this.effect === 'targeted' || this.effect === 'mouse') ? this.sourceX :
              xDimension === null ? this.randomRange(0, this.canvas.nativeElement.width) : xDimension,
          y: (this.effect === 'targeted' || this.effect === 'mouse') ? this.sourceY : this.canvas.nativeElement.height - 1,
        },
        rotation: this.randomRange(0, 2 * Math.PI),
        scale: {
          x: .75,
          y: .5,
        },
        velocity: {
          x: (this.effect === 'targeted' || this.effect === 'mouse') ? this.randomRange(-25, 25) : this.randomRange(-25, 25),
          y: (this.effect === 'targeted' || this.effect === 'mouse') ? this.randomRange(0, -30) : this.randomRange(0, -50),
        },
      });

    }
  }

  doRender() {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.confettiBits.forEach((confetto, index) => {
      const width = (confetto.dimensions.x * confetto.scale.x);
      const height = (confetto.dimensions.y * confetto.scale.y);

      // Move canvas to position and rotate
      this.ctx.translate(confetto.position.x, confetto.position.y);
      this.ctx.rotate(confetto.rotation);

      // Apply forces to velocity
      confetto.velocity.x -= confetto.velocity.x * this.drag;
      confetto.velocity.y = Math.min(confetto.velocity.y + this.gravity, this.terminalVelocity);
      confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

      // Set position
      confetto.position.x += confetto.velocity.x;
      confetto.position.y += confetto.velocity.y;

      // Delete confetti when out of frame
      if (confetto.position.y >= this.canvas.nativeElement.height) {
        this.confettiBits.splice(index, 1);
      }

      // Loop confetto x position
      if (this.effect === 'leftright' || this.effect === 'cover') {
        if (confetto.position.x > this.canvas.nativeElement.width) {
          confetto.position.x = 0;
        }
        if (confetto.position.x < 0) {
          confetto.position.x = this.canvas.nativeElement.width;
        }
      }

      // Spin confetto by scaling y
      confetto.scale.y = Math.cos(confetto.position.y * 0.1);
      this.ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

      // Draw confetto
      this.ctx.fillRect(-width / 2, -height / 2, width, height);

      // Reset transform matrix
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);

      if (this.confettiBits.length <= 100) {
        this.complete = true;
      }

    });

    requestAnimationFrame(this.doRender.bind(this));
  }

  blastConfetti() {
    if (this.effect === 'targeted' && !this.sourceX && !this.sourceY) {
      return;
    } else if (this.effect === 'mouse' && !this.sourceX && !this.sourceY) {
      return;
    }
    this.initConfetti();
  }

}
