import {Component, ElementRef} from '@angular/core';

@Component({
  selector: 'slice',
  templateUrl: './slice.component.html',
  styleUrls: ['./slice.component.scss']
})
export class SliceComponent {

  private element: any;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  open() {
    this.element.classList.add('open');
  }

  close() {
    this.element.classList.remove('open');
  }

  reveal() {
    this.element.classList.remove('is_under');
  }

  putUnder() {
    this.element.classList.add('is_under');
  }

}
