import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {Wedding, WeddingPostRequest, WeddingPostResponse, WeddingsRespone} from '../models/wedding.interface';

@Injectable({
  providedIn: 'root'
})
export class WeddingService {

  url = environment.api.planner.wedding;

  constructor(
    private http: HttpClient,
  ) {
  }

  search(query: string, page = 1, orderField?: string, orderAsc?: boolean, planner?: boolean, params: { field, value }[] = []): Observable<WeddingsRespone> {
    // If Planner set, get user id and put in url
    let url = `${this.url.list.uri}?page=${page}`;
    if (query) {
      url += `&fsearch=${query}`;
    }
    if (orderField !== null) {
      if (orderAsc === false) {
        params.push({field: 'ordering', value: `-${orderField}`});
      } else {
        params.push({field: 'ordering', value: `${orderField}`});
      }
    }

    if (params && params.length > 0) {
      url += '&' + params.map(p => p.field + '=' + p.value).join('&');
    }
    if (planner) {
      url += `&planner=${planner}`;
    }
    return this.http.get<WeddingsRespone>(url, {withCredentials: this.url.get.protected});
  }

  get(reference?: string): Observable<Wedding> {
    return this.http.get<Wedding>(`${this.url.get.uri.replace('{REFERENCE}', reference)}`, {withCredentials: this.url.get.protected});
  }

  delete(wedding: Wedding) {
    return this.http.delete(this.url.delete.uri.replace('{ID}', wedding.id.toString()), {withCredentials: this.url.delete.protected});
  }

  add(weddingData: WeddingPostRequest): Observable<WeddingPostResponse> {
    return this.http.post<WeddingPostResponse>(this.url.post.uri, weddingData, {withCredentials: this.url.post.protected});
  }

  update(weddingId: Wedding['id'], weddingData: WeddingPostRequest): Observable<WeddingPostResponse> {
    return this.http.put<WeddingPostResponse>(
      this.url.put.uri.replace('{ID}', weddingId.toString()),
      weddingData,
      {withCredentials: this.url.put.protected});
  }
}
